/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import AOS from 'aos';
import focusLock from 'dom-focus-lock';
import ResponsiveAutoHeight from 'responsive-auto-height';

document.documentElement.style.setProperty('--sw', `${window.innerWidth - document.documentElement.clientWidth}px`);

// Animate On Scroll
AOS.init({
	once: true,
	easing: 'ease-in-sine'
});

// Equellize height
if (document.querySelectorAll('.match-height').length) {
	new ResponsiveAutoHeight('.match-height');
}

// dropdown set dd height
var dropdownLists = document.querySelectorAll('.dropdown');
if (dropdownLists.length) {

	var timeout = false,
		timeoutDelay = 250;

	var recalculteDropdownHeight = function () {
		dropdownLists.forEach(el => {
			var dd = el.querySelector('.dropdown__container');
			var dropdownListContent = dd.children[0];
			dd.setAttribute('style', 'height: ' + dropdownListContent.offsetHeight + 'px');
		});
	};

	window.addEventListener('resize', function () {
		clearTimeout(timeout);
		timeout = setTimeout(recalculteDropdownHeight, timeoutDelay);
	});

	recalculteDropdownHeight();
}

// Headeroom
import Headroom from 'headroom.js';
var headroom = new Headroom(document.body);
headroom.init();

// Lightgallery
async function loadLightGallery() {
	await import(/* webpackChunkName: "lightgallery" */ 'lightgallery.js');
	await import(/* webpackChunkName: "lightgallery" */ 'lg-video.js');

	lightGallery(document.getElementsByTagName("body")[0], {
		selector: '[rel=modal]',
		download: false,
		thumbnail: false,
		fullScreen: false,
		actualSize: false,
		hash: false,
		videoMaxWidth: '1255x'
	});
}

loadLightGallery();

// Close dropdown on click
document.querySelectorAll('.dropdownn__title, dl.languages > dt').forEach(el => {
	el.addEventListener('click', () => {
		el.parentElement.classList.add('clicked');
		el.parentElement.classList.toggle('open');
	});

	el.addEventListener('blur', () => {
		// Timeout is required for iOS
		setTimeout(() => {
			el.parentElement.classList.remove('clicked');
			el.parentElement.classList.remove('open');
		}, 0);
	});

	// Prevent mousedown in next sibling (dd) to prevent click event to bubble to blur
	el.nextElementSibling.addEventListener('mousedown', e => {
		e.preventDefault();
	});

	el.addEventListener('keydown', e => {
		// Space and Enter
		if (e.keyCode === 13 || e.keyCode === 32) {
			el.click();
			e.preventDefault();
		}
	});
});

// Change dt text on selecting new item
document.querySelectorAll('.contact-card-drop-down').forEach(el => {
	var title = el.querySelector('.dropdownn__title');

	el.querySelectorAll('label').forEach(item => {

		item.addEventListener('click', e => {
			title.innerHTML = item.innerText;
		});
	});
});

// Close navigation on backdrop click or escape key
const navigationToggler = document.getElementById('nav-toggler'), navigation = document.querySelector('.nav.is-side-nav'), navigationContainer = document.querySelector('.nav.is-side-nav .nav-container');
if (navigationToggler && navigation && navigationContainer) {
	const toggle = checked => {
		navigationToggler.checked = checked;
		navigation.setAttribute('aria-expanded', checked);

		if (checked) {
			focusLock.on(navigationContainer);
			document.documentElement.classList.add('has-no-overflow');
		} else {
			focusLock.off(navigationContainer);
			document.documentElement.classList.remove('has-no-overflow');
		}
	};

	navigation.addEventListener('click', e => {
		if (!navigationContainer.contains(e.target)) {
			console.log('test');
			toggle(false);
		}
	});

	document.addEventListener('keydown', e => {
		if (e.keyCode === 27 && navigationToggler.checked) {
			toggle(false);
		}
	});

	// Keep aria-expanded in-sync with checked state
	navigationToggler.addEventListener('change', () => {
		toggle(navigationToggler.checked);
	});

	toggle(navigationToggler.checked);
}

// Jump to submitted form
document.querySelectorAll('.is-form-block, .is-multiple-form-block .dropdown__container').forEach(el => {
	var form = el.querySelector('form'),
		anchor = el.querySelector('.anchor'),
		button = el.querySelector('.button');

	if (!anchor) {
		anchor = el.querySelector('.form').getAttribute('id');
	}
	else {
		anchor = anchor.getAttribute('id');
	}

	form.setAttribute('action', form.getAttribute('action') + '#' + anchor);

	form.addEventListener('submit', function () {
		button.classList.add('is-loading');
	});
});

// Multiple forms buttons
document.querySelectorAll('.is-multiple-form-block').forEach(el => {
	var buttons = el.querySelectorAll('.button.is-light');

	buttons.forEach(button => {

		button.addEventListener('click', function () {

			buttons.forEach(buttonItem => {
				buttonItem.classList.remove('is-active');
				button.classList.add('is-active');
			});
		});
	});
});

// Change header image large height on resize
var headerImage = document.querySelector('.header-image.is-large');
if (headerImage !== null) {
	var headerImageTimeout = false,
		headerImageTtimeoutDelay = 250;

	var setActualViewHeight = function () {
		document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);
	};

	window.addEventListener('resize', function () {
		clearTimeout(headerImageTimeout);
		headerImageTimeout = setTimeout(setActualViewHeight, headerImageTtimeoutDelay);
	});
}

// file uploades
var inputs = document.querySelectorAll('.fileupload input');
Array.prototype.forEach.call(inputs, function (input) {
	var label = input.nextElementSibling;

	input.addEventListener('change', function (e) {
		var fileName = '';
		if (this.files && this.files.length > 1)
			fileName = (this.getAttribute('data-multiple-caption') || '').replace('{0}', this.files.length);
		else
			fileName = e.target.value.split('\\').pop();

		console.log(label);

		if (fileName)
			label.innerHTML = fileName;
	});
});